import { createSlice } from "@reduxjs/toolkit";

const initialState = { isLoading: false };

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showLoader(state) {
            state.isLoading = true
        },
        hideLoader(state) {
            state.isLoading = false
        }
    }
})

export const loaderActions = loaderSlice.actions;
export default loaderSlice.reducer;