import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { rootReducer } from './slices'

export default function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(createLogger()),
		preloadedState,
	})

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./slices', () => store.replaceReducer(combineReducers(rootReducer)))
	}

	return store
}