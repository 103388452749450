import {createSlice} from "@reduxjs/toolkit";

const confirmationModalSlice = createSlice({
    name: 'confirmationModal',
    initialState: { show: false },
    reducers: {
        requestConfirmation(state, action) {
            state.id = action.payload.id;
            state.message = action.payload.message;
            state.show = true;
        },
        cancelConfirmation(state) {
            state.show = false;
        },
        confirmConfirmation(state) {
            state.show = false;
        }
    }
})

export const confirmationModalActions = confirmationModalSlice.actions;
export default confirmationModalSlice.reducer;