import React from 'react';
import "normalize.css";
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import configureAppStore from "./system/store";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { history } from "./system/helpers/history";
import routes from "./system/router/routes"
import MainTemplate from "./templates/Main"
import './styles/main.scss';

const store = configureAppStore();

const App = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <MainTemplate>
                    {routes}
                </MainTemplate>
                <ToastContainer />
            </Router>
        </Provider>
    );
};

export default App;