import React from "react";
import { useSelector } from "react-redux";
import Heading from "../../components/Heading";
import Loader from "../../components/Loader";

const MainTemplate = ({children}) => {
	const userIsLoggedIn = useSelector(state => state.auth.isLoggedIn);
	const isLoading = useSelector(state => state.loader.isLoading)

	return (
		<div className="mainTemplate">
			<div className={`mainTemplate__container ${!userIsLoggedIn && "mainTemplate__container_centered"}`}>
				{isLoading && <Loader />}
				{userIsLoggedIn && <Heading />}
				{children}
			</div>
		</div>
	)
}

export default MainTemplate;