import {createSlice} from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
	? { isLoggedIn: true, user }
	: { isLoggedIn: false, user: null };


const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginSuccess(state, action) {
			state.isLoggedIn = true;
			state.user = action.payload.user;
		},
		loginFailure(state) {
			state.isLoggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.isLoggedIn = false;
			state.user = null;

			AuthService.logout();
		}
	}
});

export const authActions = authSlice.actions;
export default authSlice.reducer;