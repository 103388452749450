import axios from "axios";
import { BACKEND_API_URL } from "../api/apiUrl";

class AuthService {
	login(email, password) {
		return axios
			.post(`${BACKEND_API_URL}login`,{email, password}, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				if (response.data.accessToken) {
					localStorage.setItem("user", JSON.stringify(response.data))
				}

				return response.data;
			});
	}

	logout() {
		localStorage.removeItem("user");
	}
}

export default new AuthService();