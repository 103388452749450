import React from "react";
import Logo from "../images/loxonet.hathi.logo.png";

const Loader = () => {
	return (
		<div className="loader">
			<img className="loader__image" src={Logo} alt="Hathi" />
		</div>
	)
}

export default Loader;