import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./constansts";
import { PrivateRoute } from "../../components/PrivateRoute";
import Loader from "../../components/Loader";

const Login = React.lazy(() => import('../../pages/login'));
const Instances = React.lazy(() => import('../../pages/instances'));
const Instance = React.lazy(() => import('../../pages/instance'));
const CustomInvoiceForm = React.lazy(() => import('../../pages/customInvoiceForm'));
const NotFound404 = React.lazy(() => import('../../pages/errorPages/404'));

export default (
	<Suspense fallback={
		<Loader />
	}>
		<Switch>
			<PrivateRoute exact path={APP_ROUTES.instances} component={Instances} />
			<PrivateRoute exact path={APP_ROUTES.instance} component={Instance} />
			<PrivateRoute exact path={APP_ROUTES.instanceBilling} component={Instance} />
			<PrivateRoute exact path={APP_ROUTES.instancePayments} component={Instance} />
			<PrivateRoute exact path={APP_ROUTES.customInvoiceForm} component={CustomInvoiceForm} />
			<Route exact path={APP_ROUTES.login} component={Login} />
			<Route component={NotFound404} />
		</Switch>
	</Suspense>
);
