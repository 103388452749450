import React from 'react';
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from "../system/router/constansts";

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const userIsLoggedIn = useSelector(state => state.auth.isLoggedIn);

	return (
		<Route {...rest} render={props => (
			userIsLoggedIn
				? <Component {...props} />
				: <Redirect to={{pathname: APP_ROUTES.login, state: {from: props.location}}} />
		)} />
	)
}