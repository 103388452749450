import authReducer from "./authSlice";
import alertReducer from "./alertSlice";
import loaderReducer from "./loaderSlice";
import confirmationModalReducer from "./modalSlice";

export const rootReducer = {
    auth: authReducer,
    alert: alertReducer,
    loader: loaderReducer,
    modal: confirmationModalReducer
};