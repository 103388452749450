import { toast } from "react-toastify";

const defaultOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export const addToast = (content, options) => {
    return toast[options.type](content, { ...defaultOptions, ...options });
};

export const removeToast = (id) => toast.dismiss(id);