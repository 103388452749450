import React from "react";
import Logo from "../images/loxonet.hathi.logo.png";

const Heading = () => {
	return (
		<div className="heading">
			<img className="heading__image" src={Logo} alt="Hathi" />
			<span className="heading__title">
				Hathi - Loxonet Control Panel
			</span>
		</div>
	)
}

export default Heading;