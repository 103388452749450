import { createSlice } from "@reduxjs/toolkit";
import { addToast } from "../helpers/toast";

const alertSlice = createSlice({
	name: 'alert',
	initialState: {},
	reducers: {
		success(state, action) {
			addToast(`${action.payload}`, { type: "success", position: 'top-center' });
		},
		error(state, action) {
			addToast(`${action.payload}`, { type: "error", position: 'top-center' });
		}
	}
})

export const alertActions = alertSlice.actions;
export default alertSlice.reducer;